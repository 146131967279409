var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"dense":"","show-select":true,"single-select":false,"item-key":"id","fixed-header":"","options":_vm.pageOptions,"server-items-length":_vm.totalElements,"disable-sort":true,"hide-default-header":_vm.$vuetify.breakpoint.mobile,"footer-props":{
                  disableItemsPerPage: true,
                  itemsPerPageOptions: [_vm.itemsPerPage],
                },"items":_vm.elements,"loading":_vm.loading},on:{"update:options":function($event){_vm.pageOptions=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
                var item = ref.item;
return [_c('strong',[_vm._v("#"+_vm._s(item.id))])]}},{key:"item.dtVenc",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.dtVenc, 'DD/MM/YYYY')))])]}},{key:"item.dtPgto",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.dtPgto==="" ? "Aberta" : _vm.formatDate(item.dtPgto, 'DD/MM/YYYY')))])]}},{key:"item.valor",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v("R$ "+_vm._s(item.valor))])]}},{key:"item.desconto",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v("% "+_vm._s(item.desconto))])]}},{key:"item.valorComAtraso",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v("R$ "+_vm._s(item.valorComAtraso))])]}},{key:"item.valorFinal",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v("R$ "+_vm._s(item.valorFinal))])]}},{key:"item.comprovantePagamento",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.retornComprovantePagamento(item)))])]}},{key:"item.remessa",fn:function(ref){
                var item = ref.item;
return [(parseInt(item.remessa) > 0)?_c('span',[_vm._v(_vm._s(item.remessa))]):(item.pagseguroCodigo !== '')?_c('span',[_c('v-icon',[_vm._v("mdi-transit-connection-horizontal")])],1):_c('span',[_vm._v(_vm._s(''))])]}},{key:"item.edit",fn:function(ref){
                var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){$event.preventDefault();return _vm.showMenu($event, item)}}},[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}},(_vm.$vuetify.breakpoint.mobile)?{key:"body",fn:function(ref){
                var items = ref.items;
                var isSelected = ref.isSelected;
                var select = ref.select;
return [(items.length === 0)?_c('v-card',{staticClass:"elevation-0"},[_c('v-card-text',{staticClass:"text-center"},[_c('p',[_vm._v("Não há dados disponíveis")])])],1):_vm._e(),_c('v-list',{staticClass:"pa-0",attrs:{"dense":""}},_vm._l((items),function(item){return _c('v-list-item',{key:item.id,staticClass:"list-item"},[_c('v-list-item-avatar',[_vm._v(" #"+_vm._s(item.id)+" ")]),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.descricao +' - '+ item.nome_idCliente)}}),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.formatDate(item.dtVenc, 'DD/MM/YYYY'))+" - "+_vm._s(item.dtPgto==="" ? "Aberta" : _vm.formatDate(item.dtPgto, 'DD/MM/YYYY'))+" ")]),_c('v-list-item-subtitle',[_vm._v(" R$ "+_vm._s(item.valorFinal)+" ")])],1)],1)}),1)]}}:null],null,true),model:{value:(_vm.selection),callback:function ($$v) {_vm.selection=$$v},expression:"selection"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }