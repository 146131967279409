<template>
  <div>
    <v-data-table :headers="headers"
                  dense
                  :show-select="true"
                  :single-select="false"
                  item-key="id"
                  v-model="selection"
                  fixed-header
                  :options.sync="pageOptions"
                  :server-items-length="totalElements"
                  :disable-sort="true"
                  :hide-default-header="$vuetify.breakpoint.mobile"
                  :footer-props="{
                    disableItemsPerPage: true,
                    itemsPerPageOptions: [itemsPerPage],
                  }"
                  :items="elements" :loading="loading">
      <template v-slot:item.id="{item}">
        <strong>#{{ item.id }}</strong>
      </template>
      <template v-slot:item.dtVenc="{item}">
        <span>{{ formatDate(item.dtVenc, 'DD/MM/YYYY')}}</span>
      </template>
      <template v-slot:item.dtPgto="{item}">
        <span>{{item.dtPgto==="" ? "Aberta" : formatDate(item.dtPgto, 'DD/MM/YYYY')}}</span>
      </template>
      <template v-slot:item.valor="{item}">
        <span>R$ {{item.valor}}</span>
      </template>
      <template v-slot:item.desconto="{item}">
        <span>% {{item.desconto}}</span>
      </template>
      <template v-slot:item.valorComAtraso="{item}">
        <span>R$ {{item.valorComAtraso}}</span>
      </template>
      <template v-slot:item.valorFinal="{item}">
        <span>R$ {{item.valorFinal}}</span>
      </template>
      <template v-slot:item.comprovantePagamento="{item}">
        <span>{{retornComprovantePagamento(item)}}</span>
      </template>
      <template v-slot:item.remessa="{item}">
        <span v-if="parseInt(item.remessa) > 0">{{item.remessa}}</span>
        <span v-else-if="item.pagseguroCodigo !== ''"><v-icon>mdi-transit-connection-horizontal</v-icon></span>
        <span v-else>{{''}}</span>
      </template>
      
      <template v-slot:item.edit="{item}">
        <v-btn @click.prevent="showMenu($event, item)" small icon>
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>

      <template v-slot:body="{items, isSelected, select}" v-if="$vuetify.breakpoint.mobile">
        <v-card v-if="items.length === 0" class="elevation-0">
          <v-card-text class="text-center">
            <p>Não há dados disponíveis</p>
          </v-card-text>
        </v-card>
        <v-list dense class="pa-0">
          <v-list-item v-for="item of items" :key="item.id"
                       class="list-item">
            <v-list-item-avatar>
              #{{ item.id }}
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-text="item.descricao +' - '+ item.nome_idCliente"/>
              <v-list-item-subtitle>
                {{ formatDate(item.dtVenc, 'DD/MM/YYYY')}} - {{item.dtPgto==="" ? "Aberta" : formatDate(item.dtPgto, 'DD/MM/YYYY')}}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                 R$ {{item.valorFinal}}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
    </v-data-table>
  </div>
</template>

<script>

import {findAllFaturasParaRemessa} from '../../../api/faturas';
import ResponsiveMenu from "../../app/ResponsiveMenu";
import * as moment from 'moment';

/**
 * @emits click:edit
 * @emit click:remove
 */
export default {
  name: "FaturasParaRemessaDataTable",
  components: {ResponsiveMenu},
  props: {
    searchValues: {
      type: Object,
    },
  },
  data() {
    return {
      selection: [],
      selectedItem: null,
      loading: false,
      error: null,
      pageOptions: {
        page: 1
      },
      itemsPerPage: 0,
      totalElements: 0,
      elements: [],
      headers: [
        {
          text: 'Nº Boleto',
          value: 'id',
          align: 'start',
          width: "5%"
        },
        {
          value: 'remessa',
          text: 'NºRem / Integração',
          align: 'start',
          width: "5%"
        },
        {
          text: 'Cliente',
          value: 'nome_idCliente',
          align: 'start'
        },
        {
          text: 'Descrição',
          value: 'descricao',
          align: 'start'
        },
        {
          text: 'Mês Ref',
          value: 'mesRef',
          align: 'start'
        },
        {
          text: 'Data Venc	',
          value: 'dtVenc',
          align: 'start'
        },
        {
          text: 'Data Pgto',
          value: 'dtPgto',
          align: 'start'
        },
        {
          text: 'Valor',
          value: 'valor',
          align: 'start'
        },
        {
          text: 'Val c/ Atraso',
          value: 'valorComAtraso',
          align: 'start'
        }, 
        {
          text: 'Desconto ',
          value: 'desconto',
          align: 'start'
        },
        {
          text: 'Val pago',
          value: 'valorFinal',
          align: 'start'
        },  
        {
          text: 'Comp. Pg.',
          value: 'comprovantePagamento',
          align: 'start'
        }, 
      ],
    }
  },
  computed: {
    selectedItemName() {
      return this.selectedItem?.descricao
    },
  },
  watch: {
    selection: {
      handler() {
        this.$emit('update:selection', this.selection);
      }
    },
    searchValues: {
      deep: true,
      handler() {
        this.refresh();
      }
    },
    'pageOptions.page': {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) this.refresh(newValue);
      }
    }
  },
  methods: {
    retornComprovantePagamento(item){
      if (item.statusDaFatura === "P")  return "Fatura paga";
      if (item.comprovantePagamento === "") return "Não enviado";
      return "Enviado";
    },
    async refresh() {
      try {
        this.error = null;
        this.loading = true;
        const page = await findAllFaturasParaRemessa(this.pageOptions.page, this.searchValues);

        this.itemsPerPage = page.elementsPerPage;
        this.totalElements = page.totalElements;
        this.elements = page.elements;
        this.pageOptions.page = page.currentPage;
        this.selection = [];

      } catch (err) {
        this.error = err;
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.pageOptions.page = 1;
    this.refresh();
  }
}
</script>

<style scoped lang="scss">
.list-item {
  &::v-deep {
    .v-list-item__content {
      max-width: calc(100vw - 128px);
    }
  }
}
.list-item:not(:last-child) {
  border-bottom: 1px solid #dedede
}

</style>