"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.gerarFaturasMensal = exports.limpaLixeira = exports.recuperaDaLixeira = exports.removeDaLixeira = exports.remove = exports.gerarBoletoImtegracao = exports.gerarArquivoRemessaBanco = exports.estornar = exports.baixaManual = exports.update = exports.processarRetornoBanco = exports.gerarFaturasPorPeriodo = exports.enviaComprovantePagamento = exports.create = exports.findOne = exports.findAllFaturasClienteLogado = exports.findAllArquivosRemessa = exports.findAllFaturasAtiva = exports.findAllLixeira = exports.findAllFaturasParaRemessa = exports.findAll = void 0;
const core_1 = require("./core");
function findAll(page = 1, search) {
    return core_1.get('Faturas', 'lista', Object.assign({ pg: page }, search))
        .then(({ dados, paginacao }) => {
        if (dados.length === 0)
            return {
                elements: [],
                elementsPerPage: 0,
                totalElements: 0,
                currentPage: 0,
                totalPages: 0
            };
        return {
            elements: dados,
            elementsPerPage: paginacao.totalRegPorPagina,
            totalElements: paginacao.totalRegEncontrados,
            currentPage: paginacao.pagAtual,
            totalPages: paginacao.qtdPag
        };
    });
}
exports.findAll = findAll;
function findAllFaturasParaRemessa(page = 1, search) {
    return core_1.get('Faturas', 'listaFaturasParaRemessaBanco', Object.assign({ pg: page }, search))
        .then(({ dados, paginacao }) => {
        if (dados.length === 0)
            return {
                elements: [],
                elementsPerPage: 0,
                totalElements: 0,
                currentPage: 0,
                totalPages: 0
            };
        return {
            elements: dados,
            elementsPerPage: paginacao.totalRegPorPagina,
            totalElements: paginacao.totalRegEncontrados,
            currentPage: paginacao.pagAtual,
            totalPages: paginacao.qtdPag
        };
    });
}
exports.findAllFaturasParaRemessa = findAllFaturasParaRemessa;
function findAllLixeira(page = 1, search) {
    return core_1.get('Faturas', 'listaLixeira', Object.assign({ pg: page }, search))
        .then(({ dados, paginacao }) => {
        if (dados.length === 0)
            return {
                elements: [],
                elementsPerPage: 0,
                totalElements: 0,
                currentPage: 0,
                totalPages: 0
            };
        return {
            elements: dados,
            elementsPerPage: paginacao.totalRegPorPagina,
            totalElements: paginacao.totalRegEncontrados,
            currentPage: paginacao.pagAtual,
            totalPages: paginacao.qtdPag
        };
    });
}
exports.findAllLixeira = findAllLixeira;
function findAllFaturasAtiva(page = 1, search) {
    return core_1.get('Faturas', 'listaFaturasAtiva', Object.assign({ pg: page }, search))
        .then(({ dados, paginacao }) => {
        if (dados.length === 0)
            return {
                elements: [],
                elementsPerPage: 0,
                totalElements: 0,
                currentPage: 0,
                totalPages: 0
            };
        return {
            elements: dados,
            elementsPerPage: paginacao.totalRegPorPagina,
            totalElements: paginacao.totalRegEncontrados,
            currentPage: paginacao.pagAtual,
            totalPages: paginacao.qtdPag
        };
    });
}
exports.findAllFaturasAtiva = findAllFaturasAtiva;
function findAllArquivosRemessa(page = 1, search) {
    return core_1.get('Faturas', 'listaArquivosRemessaGerados', Object.assign({ pg: page }, search))
        .then(({ dados, paginacao }) => {
        if (dados.length === 0)
            return {
                elements: [],
                elementsPerPage: 0,
                totalElements: 0,
                currentPage: 0,
                totalPages: 0
            };
        return {
            elements: dados,
            elementsPerPage: paginacao.totalRegPorPagina,
            totalElements: paginacao.totalRegEncontrados,
            currentPage: paginacao.pagAtual,
            totalPages: paginacao.qtdPag
        };
    });
}
exports.findAllArquivosRemessa = findAllArquivosRemessa;
function findAllFaturasClienteLogado(page = 1, search) {
    return core_1.get('Faturas', 'listaFaturaClienteLogado', Object.assign({ pg: page }, search))
        .then(({ dados, paginacao }) => {
        if (dados.length === 0)
            return {
                elements: [],
                elementsPerPage: 0,
                totalElements: 0,
                currentPage: 0,
                totalPages: 0
            };
        return {
            elements: dados,
            elementsPerPage: paginacao.totalRegPorPagina,
            totalElements: paginacao.totalRegEncontrados,
            currentPage: paginacao.pagAtual,
            totalPages: paginacao.qtdPag
        };
    });
}
exports.findAllFaturasClienteLogado = findAllFaturasClienteLogado;
function findOne(id) {
    return core_1.get('Faturas', 'detalhe', { id });
}
exports.findOne = findOne;
function create(data) {
    return core_1.post('Faturas', 'cadastro', data);
}
exports.create = create;
function enviaComprovantePagamento(data) {
    return core_1.post('Faturas', 'faturasEnviaComprovantePagamento', data);
}
exports.enviaComprovantePagamento = enviaComprovantePagamento;
function gerarFaturasPorPeriodo(data) {
    return core_1.post('Faturas', 'geraFaturaCarneCliente', data);
}
exports.gerarFaturasPorPeriodo = gerarFaturasPorPeriodo;
function processarRetornoBanco(arquivoRetorno) {
    return core_1.post('Faturas', 'faturasProcessarRetornoBanco', {
        arquivoRetorno
    }).then(({ msg, logProcessamento }) => {
        return {
            msg: msg,
            logProcessamento: logProcessamento,
        };
    });
}
exports.processarRetornoBanco = processarRetornoBanco;
function update(id, data) {
    return core_1.put('Faturas', 'update', Object.assign({ id }, data));
}
exports.update = update;
function baixaManual(id, data) {
    return core_1.put('Faturas', 'faturasBaixa', Object.assign({ id }, data));
}
exports.baixaManual = baixaManual;
function estornar(id, data) {
    return core_1.put('Faturas', 'faturasEstorno', Object.assign({ id }, data));
}
exports.estornar = estornar;
function gerarArquivoRemessaBanco(idsFaturas) {
    return core_1.put('Faturas', 'faturasGerarRemessaBanco', {
        idsFaturas
    }).then(({ msg, inconsistencias, arquivoRemessa }) => {
        return {
            msg: msg,
            arquivoRemessa: arquivoRemessa,
            inconsistencias: inconsistencias,
        };
    });
}
exports.gerarArquivoRemessaBanco = gerarArquivoRemessaBanco;
function gerarBoletoImtegracao(idsFaturas) {
    return core_1.put('Faturas', 'faturasBoletoImtegracao', {
        idsFaturas
    }).then(({ msg, inconsistencias, arquivoRemessa }) => {
        return {
            msg: msg,
            arquivoRemessa: arquivoRemessa,
            inconsistencias: inconsistencias,
        };
    });
}
exports.gerarBoletoImtegracao = gerarBoletoImtegracao;
function remove(id) {
    return core_1.del('Faturas', 'exclui', { id });
}
exports.remove = remove;
function removeDaLixeira(id) {
    return core_1.del('Faturas', 'excluiDaLixeira', { id });
}
exports.removeDaLixeira = removeDaLixeira;
function recuperaDaLixeira(id) {
    return core_1.put('Faturas', 'faturasRecuperaDaLixeira', { id });
}
exports.recuperaDaLixeira = recuperaDaLixeira;
function limpaLixeira() {
    return core_1.del('Faturas', 'limpaLixeira', {});
}
exports.limpaLixeira = limpaLixeira;
function gerarFaturasMensal(mes, tipoFatura, idLocal, tipoLocacao, idGrupoLocacao) {
    return core_1.postWithParams('Faturas', 'geraFaturaMensal', {
        mesRef: mes,
        descTipo: tipoFatura,
        idLocal: idLocal,
        tipoLocacao: tipoLocacao,
        idGrupoLocacao: idGrupoLocacao
    });
}
exports.gerarFaturasMensal = gerarFaturasMensal;
